<template>
  <div class="item*container" tag="div" :list="sozlesmeTreeList" group="ustSozlesmeID">
    <div class="item-group" v-for="(el,index) in sozlesmeTreeList" :key="index">
      <div class="item"
           :style="{ 'text-align': 'center', 'color': '#ffffff', 
                     'background-color': el.sozlesmeKategoriID == 2 ? '#006cae' : 
                                         el.sozlesmeKategoriID == 1 ? '#28323b' : 
                                         el.sozlesmeKategoriID == 7 ? '#0a8f3e' : 
                                         el.sozlesmeKategoriID == 4 ? '#ff8b00' : 
                                         el.sozlesmeKategoriID == 3 ? '#006cae' : 
                                         el.sozlesmeKategoriID == 6 ? '#ce1e1e':
                                         el.sozlesmeKategoriID == 5 ? '#181f38': '#c7e3f6' }">
        {{ el.sozlesmeKategoriAdi }}
      </div>
      <draggable class="item*container" :list="el.sozlesmeler" :group="'kategori-' + el.sozlesmeID">
        <div class="item" v-for="(sozlesme, idx) in el.sozlesmeler" :key="idx"
             style="display: flex; align-items: center; justify-content: space-between; margin-left: 30px; height: 10px;">
          {{ sozlesme.sozlesmeBaslik }}
          <span class="menu-closebtn">
            <el-tooltip content='Düzenle' :open-delay="250" placement="bottom">
              <el-button style="margin-left: 100px !important;" @click="updateSozlesmeSiralama(sozlesme)" size="mini"
                         type="primary" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
          </span>
        </div>
      </draggable>
<!--      <nested-draggable class="item-sub" :sozlesmeList="el.sozlesmeler" :key="refreshSozlesme"></nested-draggable>-->
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import EventBus from '@/components/event-bus'

export default {
  props: {
    sozlesmeList: {
      required: true,
      type: Array
    },

  },
  components: {
    draggable,
  },
  name: "nested-draggable",
  mounted() {
    this.sozlesmeTreeList = this.sozlesmeList
    var self = this;
    EventBus.$on("sozlesmeListGuncelle", function (sozlesmeListeYeni) {
      self.sozlesmeTreeList = sozlesmeListeYeni;

    })


    EventBus.$on("refreshSozlesmeList", function (bool) {
      self.refreshSozlesme += 1;
    })

  },

  watch: {
    sozlesmeTreeList(val) {
      this.refreshSozlesme += 1;
    }
  },

  methods: {
    updateSozlesmeItem(value) {
      EventBus.$emit("sozlesmeListStartUpdate", value);
    },
    sozlesmeSil(value) {
      EventBus.$emit("deleteSozlesmeItem", value);
    },
    updateSozlesmeSiralama(sozlesme) {
      window.scrollTo(0, 0)
      this.$emit('show-collapse', sozlesme);
    }

  },

  data() {
    return {
      sozlesmeTreeList: [],
      refreshSozlesme: 0,
    }
  }
};
</script>


<style scoped lang="scss">
.item-container {
  max-width: 20rem;
  margin: 0;
}

.item {
  padding: 1.2rem;
  // border-radius: 4px;
  border: solid #b8c0c5 1px;
  background: #f3f7fa;
  margin: 1%;
  font-weight: 500 !important;
  font-size: 1.15rem !important;
}

.item-sub {
  margin: 0 0 0 2rem;
}

.menu-closebtn {
  margin-left: 100px !important;
  justify-content: flex-end !important;
}

.sozlesme-closebtn:hover {
  cursor: pointer;
}
</style>