import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "menu";

var menuService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    menuListe(menuKategoriID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            kategoriID: menuKategoriID,
            durum: 1,
            serviceName: moduleName,
            methodName: "menuListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },


    menuTanimla(ustMenuID, menuKategoriID, icon, baslik, link) {
        //$data['ustMenuID'],$data['menuKategoriID'],$data['baslik'],$data['link'],$data['durum'],$data['enBaslik'],
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            ustMenuID: ustMenuID,
            menuKategoriID: menuKategoriID,
            menuBaslik: baslik,
            menuIcon: icon,
            link: link,
            durum: 1,
            menuSiraNo: 0,
            serviceName: moduleName,
            methodName: "menuEkle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    menuGuncelle(menuID, ustMenuID, menuKategoriID, baslik, icon) {
        // $data['menuID'],$data['ustMenuID'],$data['menuKategoriID'],$data['baslik'],$data['enBaslik'], $data['durum'],
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            menuID: menuID,
            ustMenuID: ustMenuID,
            menuKategoriID: menuKategoriID,
            menuBaslik: baslik,
            menuIcon: icon,
            menuSiraNo: 0,
            durum: 1,
            serviceName: moduleName,
            methodName: "menuGuncelle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    menuListeGuncelle(menuKategoriId, list) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            menuKategoriID: menuKategoriId,
            menuler: JSON.stringify(list),
            // menuler: [{menuID: 1, ustMenuID: 0, siraNo: 3 }, {menuID: 2, ustMenuID: 0, siraNo: 3 }],
            durum: 1,
            serviceName: moduleName,
            methodName: "menuSiraGuncelle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },


    menuSil(menuID, link) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            menuID: menuID,
            link: link,
            serviceName: moduleName,
            methodName: "menuSil"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    }

}

export default menuService